module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"full":"(min-width: 1920px)","xl2":"(min-width: 1536px)","laptopL":"(min-width: 1440px)","xl":"(min-width: 1280px)","lg":"(min-width: 992px)","md":"(min-width: 768px)","sm":"(min-width: 480px)","xs":"(min-width: 428px)"}},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/images/favicon.png","icons":[{"src":"src/images/maskable.png","sizes":"196x196","type":"image/png","purpose":"any maskable"},{"src":"public/icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"public/icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"public/icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"public/icons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1e087325378d6fd4ea8b6f9fd3ee4ea1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://digitalgoals.net/api/graphql"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
